import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const FooterContainer = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 20px;
  font-size: 12px;
  color: ${props => props.isPhilosophyPage ? '#000000' : 'rgba(255, 255, 255, 0.6)'};
  text-align: center;
  background: ${props => props.isPhilosophyPage ? 'white' : `linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.02) 5%,
    rgba(0, 0, 0, 0.05) 10%,
    rgba(0, 0, 0, 0.08) 20%,
    rgba(0, 0, 0, 0.12) 35%,
    rgba(0, 0, 0, 0.18) 50%,
    rgba(0, 0, 0, 0.22) 100%
  )`};
  backdrop-filter: ${props => props.isPhilosophyPage ? 'none' : 'blur(5px)'};
  z-index: 999;
  opacity: ${props => props.isVisible ? 1 : 0};
  visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
  transition: opacity 0.3s ease, visibility 0.3s ease;

  @media (max-width: 768px) {
    font-size: 10px;
    padding: 12px 15px;
    background: ${props => props.isPhilosophyPage ? 'none' : `linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.03) 5%,
      rgba(0, 0, 0, 0.07) 10%,
      rgba(0, 0, 0, 0.12) 20%,
      rgba(0, 0, 0, 0.16) 35%,
      rgba(0, 0, 0, 0.22) 50%,
      rgba(0, 0, 0, 0.25) 100%
    )`};
  }
`;

const FooterText = styled.p`
  margin: 0;
  font-family: 'Inter', sans-serif;
`;

const Copyright = styled.span`
  font-size: 14px;
  margin-right: 4px;
`;

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();
  const isPhilosophyPage = location.pathname === '/philosophy';

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      
      // Show footer when near bottom (within 50px)
      const isNearBottom = scrollTop + windowHeight >= documentHeight - 50;
      setIsVisible(isNearBottom);
    };

    window.addEventListener('scroll', handleScroll);
    // Initial check
    handleScroll();
    
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <FooterContainer 
      isVisible={isVisible} 
      isPhilosophyPage={isPhilosophyPage}
    >
      <FooterText>
        <Copyright>©</Copyright>
        information-company.com is Infocom's property, registered in the Grand Duchy of Luxembourg, trade code: B279794
      </FooterText>
    </FooterContainer>
  );
};

export default Footer; 