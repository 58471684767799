import React, { useState } from 'react';
import styled from 'styled-components';
import { fadeIn } from './HomePage';
import oxmanSvg from './oxman2.jpg';
import { Link } from 'react-router-dom';

const Navbar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 70px;
  background: black;
  display: flex;
  align-items: center;
  padding: 0 40px;
  z-index: 100;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;

  @media (max-width: 768px) {
    height: 60px;
    padding: 0 20px;
  }
`;

const NavContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;

const NavBrand = styled.span`
  color: white;
  font-size: 24px;
  font-weight: 200;
  letter-spacing: 0.5px;
  
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const BackButton = styled(Link)`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  border: none;
  outline: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  &:active {
    background: rgba(255, 255, 255, 0.15);
  }

  svg {
    width: 18px;
    height: 18px;
    fill: white;
    transform: rotate(180deg);
  }

  @media (max-width: 768px) {
    width: 36px;
    height: 36px;
    
    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: white;
  animation: ${fadeIn} 1s ease-in;
  position: relative;
  padding: 40px;
  padding-top: 90px;
  padding-bottom: 80px;
  overflow-x: hidden;

  > * {
    position: relative;
    z-index: 1;
  }

  @media (max-width: 768px) {
    padding-top: 80px;
  }
`;

const OxmanImage = styled.img`
  width: 900px;
  height: auto;
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
  border-radius: 10px;

  @media (max-width: 1800px) {
    width: 1000px;
  }

  @media (max-width: 1400px) {
    width: 900px;
  }

  @media (max-width: 1200px) {
    width: 800px;
  }

  @media (max-width: 1024px) {
    width: 700px;
  }

  @media (max-width: 768px) {
    width: 95%;
    max-width: 600px;
  }
`;

const Title = styled.h1`
  color: black;
  font-size: 56px;
  font-weight: 200;
  margin-bottom: 60px;
  text-align: center;
  margin-top: 20px;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    font-size: 36px;
    margin-bottom: 40px;
    margin-top: 15px;
  }
`;

const Subtitle = styled.div`
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  margin-bottom: 40px;
  font-weight: 300;
  letter-spacing: 0.3px;

  @media (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 30px;
    padding: 0 20px;
  }
`;

const ImageCredit = styled.div`
  font-size: 12px;
  color: #000000;
  font-style: italic;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 40px;
  
  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

const FullScreenOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => props.isOpen ? 1 : 0};
  pointer-events: ${props => props.isOpen ? 'auto' : 'none'};
  transition: opacity 0.3s ease;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
`;

const FullScreenImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 20px;
  box-sizing: border-box;
`;

const CloseFullScreenButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 1001;
  -webkit-tap-highlight-color: transparent;
  outline: none;

  &:hover {
    background: rgba(255, 255, 255, 0.3);
    transform: rotate(90deg);
  }

  &:active {
    background: rgba(255, 255, 255, 0.25);
  }

  &:focus {
    outline: none;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: white;
    transition: transform 0.3s ease;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const Description = styled.div`
  max-width: 800px;
  text-align: justify;
  margin: 0 auto 60px;
  line-height: 1.8;
  color: rgba(0, 0, 0, 0.95);
  font-weight: 300;
  letter-spacing: 0.3px;
  padding: 0 20px;

  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 40px;
  }
`;

const Quote = styled.div`
  text-align: center;
  margin: 20px auto;
  font-style: italic;
  color: rgba(0, 0, 0, 0.85);
  
  .quote-text {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .quote-author {
    font-size: 16px;
    font-weight: 300;
  }

  @media (max-width: 768px) {
    .quote-text {
      font-size: 18px;
    }
    
    .quote-author {
      font-size: 14px;
    }
  }
`;

const BackgroundText = styled.div`
  position: fixed;
  top: 50%;
  left: 0;
  height: auto;
  font-size: 48px;
  font-weight: 200;
  color: rgba(0, 0, 0, 0.16);
  pointer-events: none;
  z-index: 0;
  text-transform: none;
  writing-mode: vertical-lr;
  transform: rotate(180deg) translateY(50%);
  display: flex;
  align-items: center;
  padding-right: 40px;
  letter-spacing: 1px;

  span {
    display: inline;
    white-space: nowrap;
  }

  @media (max-width: 1400px) {
    font-size: 42px;
  }

  @media (max-width: 1200px) {
    font-size: 36px;
    padding-right: 30px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

function Philosophy() {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleImageClick = () => {
    setIsFullScreen(true);
  };

  return (
    <>
      <Navbar>
        <NavContent>
          <LeftSection>
            <BackButton to="/" aria-label="Back to home">
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"/>
              </svg>
            </BackButton>
          </LeftSection>
          <NavBrand>infocom</NavBrand>
        </NavContent>
      </Navbar>
      <Container>
        <BackgroundText>
          <span>design = art + function</span>
        </BackgroundText>
        <Title>02. Design</Title>
        <Subtitle>Design is not only what you feel and see, it's how the system functions.</Subtitle>
        
        <Description>
          At Infocom, we believe that technology should be as elegant as it is intelligent.

          Our approach to application development is holistic—balancing function, design, stability, and algorithmic rigor with equal care. A system is only as strong as its foundation, and for us, that means crafting software that is not just powerful, but intuitive, resilient, and thoughtfully designed.

          Whether refining the smallest detail or engineering large-scale architectures, we focus on solutions that are as refined as they are robust.

          At the intersection of precision and creativity, Infocom builds not just applications, but experiences that endure.
        </Description>

        <Quote>
          <div className="quote-text">"The details are not the details. They make the design."</div>
          <div className="quote-author">Charles Eames</div>
        </Quote>

        <ImageWrapper onClick={handleImageClick}>
          <OxmanImage 
            src={oxmanSvg} 
            alt="Neri Oxman's Krebs Cycle of Creativity - A diagram illustrating the relationship between science, engineering, design and art" 
            title="Neri Oxman's Krebs Cycle of Creativity Diagram"
            loading="eager"
            width="900"
            height="900"
          />
          <ImageCredit>Image: Neri Oxman's Krebs Cycle of Creativity (2016)</ImageCredit>
        </ImageWrapper>

        <FullScreenOverlay isOpen={isFullScreen}>
          <CloseFullScreenButton onClick={() => setIsFullScreen(false)}>
            <svg viewBox="0 0 24 24">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
            </svg>
          </CloseFullScreenButton>
          <FullScreenImage 
            src={oxmanSvg} 
            alt="Neri Oxman's Krebs Cycle of Creativity - A diagram illustrating the relationship between science, engineering, design and art"
            title="Neri Oxman's Krebs Cycle of Creativity Diagram"
          />
        </FullScreenOverlay>
      </Container>
    </>
  );
}

export default Philosophy; 