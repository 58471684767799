import { 
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Collapse,
  useMediaQuery,
  useTheme
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import styled from 'styled-components';
import logo from './logo_white.svg';

const StyledLink = styled(Link)`
  color: #333;
  text-decoration: none;
  padding: 8px 16px;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #333;
    transition: width 0.3s;
  }
  
  &:hover::after {
    width: 100%;
  }
`;

const NavbarContainer = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
  }
`;

const BrandName = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 24px;
  font-weight: 300;
`;

const MenuButton = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  -webkit-tap-highlight-color: transparent;
  outline: none;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  svg {
    width: 24px;
    height: 24px;
    fill: white;
    transition: transform 0.3s ease;
  }

  ${props => props.isOpen && `
    svg {
      transform: rotate(90deg);
    }
  `}
`;

export const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const navLinks = [
    { to: 'https://www.financial-portfolio.io/', text: 'Finance' },
    // { to: '/coming-soon', text: 'Law' },
    { to: 'https://info-com.io', text: 'Media Technology' },
    { to: '/coming-soon', text: 'News & Opinion' }
  ];

  return (
    <Box>
      <AppBar position="sticky" elevation={0} sx={{ bgcolor: 'rgba(0, 0, 0, 0.9)', borderBottom: '1px solid #555' }}>
        <Toolbar sx={{ justifyContent: 'space-between', px: { xs: 2, sm: 4 } }}>
          <Link to="/">
            <img src={logo} alt="IC Logo" style={{ height: '2.5rem' }} />
          </Link>

          {isMobile ? (
            <IconButton onClick={() => setIsOpen(!isOpen)}>
              <MenuIcon style={{ color: '#fff' }} />
            </IconButton>
          ) : (
            <Box sx={{ display: 'flex', gap: 4 }}>
              {navLinks.map((link) => (
                <StyledLink key={link.to} to={link.to} style={{ color: '#fff' }}>
                  {link.text}
                </StyledLink>
              ))}
            </Box>
          )}
        </Toolbar>
        {isMobile && (
          <Collapse in={isOpen}>
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2, bgcolor: '#111' }}>
              {navLinks.map((link) => (
                <StyledLink key={link.to} to={link.to} style={{ color: '#fff' }} onClick={() => setIsOpen(false)}>
                  {link.text}
                </StyledLink>
              ))}
            </Box>
          </Collapse>
        )}
      </AppBar>
    </Box>
  );
};

function Navbar({ onMenuClick, isMenuOpen }) {
  return (
    <NavbarContainer>
      <BrandName to="/">infocom</BrandName>
      <MenuButton onClick={onMenuClick} isOpen={isMenuOpen}>
        <svg viewBox="0 0 24 24">
          {isMenuOpen ? (
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
          ) : (
            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
          )}
        </svg>
      </MenuButton>
    </NavbarContainer>
  );
}

export default Navbar;
