// import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import logoWhite from './logo_white.svg';
import abstract from './pipes2.jpg'; 
import aiImage from '../assets/AI.jpeg';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Navbar from './Navbar';


export const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  animation: ${fadeIn} 1s ease-in;
  padding-bottom: 200px;

  @media (max-width: 768px) {
    padding-bottom: 100px;
  }
`;

const BackgroundLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const ImageLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 100%;
  opacity: 0.9;
  background: 
    linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.05) 50%,
      rgba(0, 0, 0, 0) 100%
    ),
    url(${aiImage}) left center/cover no-repeat;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0) 100%
  );
  -webkit-mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0) 100%
  );
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
    pointer-events: none;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const ImageRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 60%;
  height: 100%;
  background: 
    linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.15) 0%,
      rgba(0, 0, 0, 0.2) 50%,
      rgba(0, 0, 0, 0.25) 100%
    ),
    url(${abstract}) right center/cover no-repeat;
  opacity: 0.95;
  mask-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0) 100%
  );
  -webkit-mask-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0) 100%
  );
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 100%;
    background: linear-gradient(
      to left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.3) 100%
    );
    pointer-events: none;
  }

  @media (max-width: 768px) {
    width: 100%;
    mask-image: none;
    -webkit-mask-image: none;
    opacity: 0.7;
    background: 
      linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.05) 0%,
        rgba(0, 0, 0, 0.08) 50%,
        rgba(0, 0, 0, 0.1) 100%
      ),
      url(${abstract}) center center/cover no-repeat;
  }
`;

const CenteredContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 1;
  margin-top: 120px; // Increased from 80px to 120px to move content down further
`;

const Logo = styled.img`
  width: 120px;
  margin-bottom: 20px;
  
  @media (max-width: 768px) {
    width: 100px;
    margin-bottom: 10px;
  }
`;

const NavContainer = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 0px;
  font-size: 1.2rem !important;
  justify-content: center;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }
`;

const NavItem = styled.div`
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
  position: relative;
  padding: 5px 10px;
  opacity: 0.8;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 3px 8px;
  }
`;

const SocialContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 768px) {
    top: auto;
    bottom: 100px;
    flex-direction: column;
  }
`;

const SocialLink = styled.a`
  opacity: 0.7;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: #ffffff; // White for visibility
  }
`;

const BrandText = styled.div`
  position: absolute;
  bottom: 0px;
  left: 20px;
  display: flex;
  flex-direction: column;
  gap: 0;
  margin-bottom: 60px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const MainText = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: ${props => props.fontSize ? props.fontSize : 'clamp(3rem, 15vw, 12rem)'};
  font-weight: ${props => props.fontWeight ? props.fontWeight : '200'};
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: -1px;
  line-height: ${props => props.lineHeight ? props.lineHeight : '1'};
  
  @media (max-width: 768px) {
    font-size: 48px;
    line-height: 1;
  }
`;

const SubText = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 20px;
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  
  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 15px;
    gap: 3px;
  }
`;

const MenuOverlay = styled.div`
  position: fixed;
  top: 0;
  left: ${props => props.isOpen ? '0' : '-100%'};
  width: 400px;
  height: 100vh;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  transition: left 0.3s ease;
  padding: 80px 0 0;
  box-sizing: border-box;
  z-index: 999;

  @media (max-width: 768px) {
    right: ${props => props.isOpen ? '0' : '-100%'};
    left: auto;
    width: 300px;
    transition: right 0.3s ease;
  }
`;

const MenuItem = styled(Link)`
  display: block;
  padding: 25px 30px;
  margin: 0;
  color: white;
  text-decoration: none;
  transition: background 0.3s ease;
  font-weight: 300;
  letter-spacing: 0.5px;
  border-radius: 0;
  background: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 24px;

  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 768px) {
    padding: 15px;
    font-size: 16px;
  }
`;

const MenuSection = styled.div`
  margin: 0;
  padding: 0;

  &:not(:last-child) {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const MenuTitle = styled.h3`
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
  padding: 30px 30px 20px;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 20px 15px 10px;
  }
`;

const MenuContainer = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;

  @media (max-width: 768px) {
    display: none; // Hide on mobile since we have the navbar
  }
`;

const MenuButton = styled.button`
  width: 65px;
  height: 65px;
  border-radius: 32.5px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  -webkit-tap-highlight-color: transparent;
  outline: none;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  svg {
    width: 32px;
    height: 32px;
    fill: white;
    transition: transform 0.3s ease;
  }

  ${props => props.isOpen && `
    svg {
      transform: rotate(90deg);
    }
  `}
`;

function HomePage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Container>
      <Navbar 
        onMenuClick={() => setIsMenuOpen(!isMenuOpen)} 
        isMenuOpen={isMenuOpen} 
      />
      
      <MenuContainer>
        <MenuButton 
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          isOpen={isMenuOpen}
        >
          <svg viewBox="0 0 24 24">
            {isMenuOpen ? (
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
            ) : (
              <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
            )}
          </svg>
        </MenuButton>
      </MenuContainer>

      <BackgroundLayer>
        <ImageLeft />
        <ImageRight />
      </BackgroundLayer>

      <SocialContainer>
        <SocialLink 
          href="https://www.linkedin.com/in/alexandre-nesovic-627004144" 
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Connect with us on LinkedIn">
          <svg viewBox="0 0 24 24" aria-hidden="true">
            <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
          </svg>
        </SocialLink>
        <SocialLink 
          href="mailto:hello@info-com.io" 
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Email us">
          <svg viewBox="0 0 24 24" aria-hidden="true">
            <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
          </svg>
        </SocialLink>
      </SocialContainer>

      <CenteredContent>
        <header>
          <Logo src={logoWhite} alt="Information Company Logo" />
        </header>

        <main>
          <nav aria-label="Main navigation">
            <NavContainer>
              <NavItem>Financial Research</NavItem>
              <NavItem>Software Engineering</NavItem>
              <NavItem>AI Orchestration</NavItem>
            </NavContainer>
          </nav>
        </main>
      </CenteredContent>

      <BrandText>
        <MainText>infocom</MainText>
        <SubText>
          We don't just "ship" software • We expand your markets
        </SubText>
      </BrandText>

      <MenuOverlay isOpen={isMenuOpen}>
        <MenuSection>
          <MenuTitle>Navigation</MenuTitle>
          <MenuItem to="/about-infocom">01. Software Engineering</MenuItem>
          <MenuItem to="/philosophy">02. Design</MenuItem>
        </MenuSection>
      </MenuOverlay>
    </Container>
  );
}

export default HomePage;