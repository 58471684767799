import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { fadeIn } from './HomePage';
import { Link } from 'react-router-dom';
import abstract from '../assets/deepmind.jpg';
import { Helmet } from 'react-helmet';
import alexNesovic from './Alex Nesovic.jpg';

const Container = styled.div`
  min-height: 100vh;
  margin: 0;
  animation: ${fadeIn} 1s ease-in;
  color: white;
  position: relative;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const BackgroundLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const ImageLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 100%;
  background: rgba(17, 17, 17, 0.35);
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 60%,
    rgba(0, 0, 0, 0) 100%
  );
  -webkit-mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 60%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: -1;
  
  @media (max-width: 768px) {
    display: none;
  }
`;

const ImageRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: 
    linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.25) 0%,
      rgba(0, 0, 0, 0.3) 50%,
      rgba(0, 0, 0, 0.95) 95%,
      rgba(0, 0, 0, 1) 100%
    ),
    url(${abstract}) center center/cover no-repeat fixed;
  opacity: 0.9;
  z-index: -2;
  
  @media (max-width: 768px) {
    width: 100%;
    opacity: 0.65;
    background: 
      linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.25) 0%,
        rgba(0, 0, 0, 0.3) 50%,
        rgba(0, 0, 0, 0.95) 95%,
        rgba(0, 0, 0, 1) 100%
      ),
      url(${abstract}) center center/cover no-repeat fixed;
  }
`;

const BackButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  margin-bottom: 40px;
  margin-left: 40px;
  transition: background 0.3s ease;
  border: none;
  outline: none;
  text-decoration: none;

  @media (max-width: 768px) {
    margin-left: 0;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  svg {
    width: 30px;
    height: 30px;
    fill: white;
    transform: rotate(180deg);
  }
`;

const CompanyName = styled.h1`
  font-size: 72px;
  font-weight: 200;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.9);
  margin: 0;
  position: fixed;
  right: 40px;
  top: 40px;
  z-index: 2;
  
  @media (max-width: 768px) {
    position: absolute;
    font-size: 48px;
    top: 20px;
    right: 20px;
  }
`;

const MainTitle = styled.h2`
  font-size: 36px;
  margin-bottom: 3vh;
  margin-left: 40px;
  font-weight: 300;
  letter-spacing: 0.5px;
  
  @media (max-width: 768px) {
    font-size: 28px;
    margin-left: 0;
    margin-bottom: 2vh;
  }
`;

const WelcomeText = styled.p`
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 24px;
  font-weight: 300;
  letter-spacing: 0.5px;
  text-align: justify;
  
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 1.5;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 32px;
  font-weight: 300;
  letter-spacing: 0.5px;
  text-align: justify;
  
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 1.5;
  }
`;

const Section = styled.div`
  min-height: 100vh;
  position: relative;
  padding: 40px;
  box-sizing: border-box;
  overflow: visible;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const FirstSection = styled(Section)`
  position: relative;
  scroll-snap-align: start;
  background: transparent;
`;

const SecondSection = styled(Section)`
  background-color: #000000;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 100px;
  scroll-snap-align: start;
  min-height: 100vh;
  margin-bottom: 0;
  padding-bottom: 0;
  margin-top: -2px;

  &::before {
    content: '';
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(to bottom, transparent, #000000);
    z-index: 1;
  }

  strong {
    color: #ffffff;
  }

  @media (max-width: 768px) {
    padding: 20px;
    padding-top: 60px;
    padding-bottom: 0;
    align-items: flex-start;
    height: auto;
    min-height: 100vh;
  }
`;

const FounderImageContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 45%;
  height: 100%;
  overflow: hidden;
  opacity: 0.85;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #000000 0%, transparent 30%);
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const FounderImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  filter: grayscale(100%);
`;

const Content = styled.div`
  max-width: 55%;
  line-height: 1.8;
  font-size: 16px;
  margin-left: 40px;
  font-weight: 300;
  letter-spacing: 0.5px;
  position: relative;
  z-index: 1;
  padding: 2vh 0 40px 0;
  flex-grow: 1;

  @media (max-width: 768px) {
    margin-left: 0;
    max-width: 100%;
    padding: 1vh 0 40px 0;
  }

  p {
    margin-bottom: 32px;
    
    &:last-child {
      margin-bottom: 20px;
    }
  }

  ${SecondSection} & {
    color: #ffffff;
    
    strong {
      color: #ffffff;
      font-weight: 400;
    }
  }
`;

const ScrollIndicator = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  outline: none;
  cursor: pointer;
  position: fixed;
  right: 40px;
  bottom: 40px;
  margin: 0;
  transition: all 0.3s ease;
  padding: 0;
  z-index: 100;
  
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  svg {
    width: 30px;
    height: 30px;
    fill: white;
    transition: transform 0.3s ease;
    transform: ${props => props.className === 'down' ? 'rotate(90deg)' : 'rotate(-90deg)'};
  }

  @media (max-width: 768px) {
    display: none;
  }

  ${SecondSection} & {
    display: none;
  }
`;


const SectionTitle = styled.h2`
  font-size: 22px;
  margin-bottom: 32px;
  margin-top: 40px;
  font-weight: 300;
  letter-spacing: 0.5px;
  
  @media (max-width: 768px) {
    font-size: 19px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
`;

const ImageCredit = styled.div`
  position: absolute;
  bottom: 10px;
  right: 20px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  font-style: italic;
  letter-spacing: 0.5px;
  z-index: 1;
  
  @media (max-width: 768px) {
    bottom: 15px;
    right: 10px;
    font-size: 10px;
  }
`;

const LanguageToggle = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  z-index: 2;
`;

const MobileFounderImage = styled.img`
  display: none;
  
  @media (max-width: 768px) {
    display: block;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: 10px auto 20px;
    object-fit: cover;
    filter: grayscale(100%);
  }
`;

function AboutInfocom() {
  const [language, setLanguage] = useState('en');
  const secondSectionRef = useRef(null);
  const firstSectionRef = useRef(null);
  const [isFirstSection, setIsFirstSection] = useState(true);

  const toggleLanguage = () => {
    setLanguage((prevLang) => (prevLang === 'en' ? 'fr' : 'en'));
  };

  const scrollToSection = () => {
    if (isFirstSection) {
      secondSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else {
      firstSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const firstSectionBottom = firstSectionRef.current?.getBoundingClientRect().bottom;
      setIsFirstSection(firstSectionBottom > window.innerHeight / 2);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <Helmet>
        <title>Infocom | Software Development in Luxembourg & Europe</title>
        <meta name="description" content="Leading software development company in Luxembourg. Specializing in AI-driven solutions, financial technology, and intelligence orchestration across Europe. Fast development without corporate overhead." />
        
        {/* Additional meta tags */}
        <meta name="keywords" content="software development luxembourg, AI development europe, financial technology, rapid prototyping, enterprise software development, luxembourg tech company, european software solutions, intelligence orchestration" />
        <meta property="og:title" content="Infocom | Software Development in Luxembourg & Europe" />
        <meta property="og:description" content="Leading software development company in Luxembourg. Specializing in AI-driven solutions, financial technology, and intelligence orchestration across Europe." />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_LU" />
        
        {/* Structured Data for better SEO */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Infocom",
            "description": "Software development company specializing in AI-driven solutions and intelligence orchestration",
            "url": "https://information-company.com",
            "address": {
              "@type": "PostalAddress",
              "addressCountry": "LU",
              "addressRegion": "Luxembourg"
            },
            "founder": {
              "@type": "Person",
              "name": "Alex Nesovic",
              "jobTitle": "Founder & Chief Technology Officer",
              "image": {
                "@type": "ImageObject",
                "url": alexNesovic,
                "caption": "Alex Nesovic - Founder & CTO of Infocom"
              }
            },
            "knowsAbout": [
              "Software Development",
              "Artificial Intelligence",
              "Financial Technology",
              "Intelligence Orchestration",
              "Enterprise Software Solutions"
            ],
            "areaServed": {
              "@type": "GeoCircle",
              "geoMidpoint": {
                "@type": "GeoCoordinates",
                "latitude": "49.8153",
                "longitude": "6.1296"
              },
              "geoRadius": "2000 km"
            }
          })}
        </script>
        
        {/* Add image metadata */}
        <meta property="og:image" content={alexNesovic} />
        <meta property="og:image:alt" content="Alex Nesovic - Founder & CTO of Infocom" />
        <meta name="image" content={alexNesovic} />
      </Helmet>
      <Container>
        <FirstSection ref={firstSectionRef}>
          <LanguageToggle onClick={toggleLanguage}>
            {language === 'en' ? 'Français' : 'English'}
          </LanguageToggle>
          <BackgroundLayer>
            <ImageLeft />
            <ImageRight />
          </BackgroundLayer>
          <BackButton to="/" aria-label={language === 'en' ? 'Back to home' : 'Retour à l\'accueil'}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"/>
            </svg>
          </BackButton>
          <CompanyName>infocom</CompanyName>
          <MainTitle>{language === 'en' ? 'Market Expansion, Lower Costs, No Corporate Overhead' : 'Expansion de Marché, Coûts Réduits, Sans Bureaucratie d\'Entreprise'}</MainTitle>
          <Content>
            {language === 'en' ? (
              <>
                <WelcomeText>
                  Based in Luxembourg and serving clients in Europe and United States, Infocom is a software development company building applications 
                  across diverse industries. While we excel in intelligence orchestration, our solutions span financial markets, events, sports sectors, and beyond.
                </WelcomeText>

                <Paragraph>
                  We don't just "ship" applications. We unlock new market potential for our partners. 
                </Paragraph>

                <Paragraph>
                  <strong>How do we do this?</strong>
                  <br /><br />
                  Through two key approaches: 1. Deep understanding of what we call the "domain" (the business and its context) including active market research, and 2. Direct involvement in domain design. This comprehensive approach allows us to build solutions that scale into powerful general-purpose applications. Our vision and execution have earned us <strong>strategic equity positions</strong> in partner companies, making us a key driver of their market expansion.
                </Paragraph>

                <Paragraph>
                  <strong>Don't choose between design and function</strong>
                  <br /><br />
                  The corporate world struggles to find the sweet spot between design excellence and technical robustness, often compromising one for the other or charging astronomical fees to deliver both. At Infocom, we've cracked this code. Our solutions marry elegant design with powerful functionality while maintaining market leading value.
                </Paragraph>

                <Paragraph>
                  <Link to="/philosophy" style={{ color: 'inherit', textDecoration: 'underline' }}>
                    {language === 'en' ? 'See our philosophy' : 'Voir notre philosophie'}
                  </Link>
                </Paragraph>

                <SectionTitle>
                  {language === 'en' ? 'Headcount is not a success metric anymore' : 'Le nombre d\'employés n\'est plus un indicateur de succès'}
                </SectionTitle>

                <Paragraph>
                  Today's most efficient companies focus on maximizing impact per employee rather than expanding team size.
                  This shift represents a fundamental change in how software companies operate, focusing on leverage through technology rather than headcount.
                </Paragraph>

                <Paragraph>
                  This is not new as Navil Ravikant, founder of AngelList, <a href="https://www.youtube.com/watch?v=rIXx617xVMo" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'underline' }}>has been saying for years</a>. But AI is dramatically accelerating this trend.
                  On one hand we have multi billion dollar startups run by teams of 10-20 people. On the other hand we have companies with 1000+ employees that are struggling to keep up with the pace of change, bogged down by bureaucracy and therefore extremely expensive costs. <a href="https://www.youtube.com/watch?v=z0wt2pe_LZM" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'underline' }}>Y Combinator</a>, which has helped found over 5000 startups with a combined valuation of $600B, predicts we'll see more billion-dollar companies managed by single-digit teams.
                </Paragraph>

                <Paragraph>
                  If you want to know more about the technicalities behind our rationale and would like us to build your product, contact us: <strong>hello@information-company.com</strong>
                </Paragraph>
              </>
            ) : (
              <>
                <WelcomeText>
                  Basée au Luxembourg et servant des clients en Europe et aux États-Unis, Infocom est une entreprise de développement de logiciels qui crée des applications 
                  dans divers secteurs. Bien que nous excellions dans l'orchestration de l'intelligence, nos solutions couvrent les marchés financiers, les événements, 
                  les secteurs sportifs, et au-delà.
                </WelcomeText>

                <Paragraph>
                  Nous ne faisons pas que "livrer" des applications. Nous débloquons de nouveaux potentiels de marché pour nos partenaires.
                </Paragraph>

                <Paragraph>
                  <strong>Comment y parvenons-nous ?</strong>
                  <br /><br />
                  À travers deux approches clés : 1. Une compréhension approfondie de ce que nous appelons le "domaine" (l'entreprise et son contexte) incluant une recherche active du marché, et 2. Une implication directe dans la conception du domaine. Cette approche globale nous permet de construire des solutions qui évoluent en applications polyvalentes puissantes. Notre vision et notre exécution nous ont valu des <strong>positions stratégiques en capital</strong> dans des entreprises partenaires, faisant de nous un moteur clé de leur expansion sur les marchés.
                </Paragraph>

                <Paragraph>
                  Dès le départ, nous généralisons les idées et les implémentations concrètes pour une utilisation dans différents secteurs. 
                  Nous y parvenons en exploitant les dernières techniques et outils de développement.
                </Paragraph>

                <Paragraph>
                  <strong>N'arbitrez pas entre design et fonctionnalité</strong>
                  <br /><br />
                  Le monde corporate peine à trouver l'équilibre parfait entre l'excellence du design et la robustesse technique, sacrifiant souvent l'un pour l'autre ou facturant des sommes astronomiques pour livrer les deux. Chez Infocom, nous avons résolu cette équation. Nos solutions marient design élégant et fonctionnalité puissante tout en maintenant une valeur inégalée sur le marché. Il ne s'agit pas d'économies, mais d'offrir une qualité exceptionnelle qui a du sens pour votre entreprise.
                </Paragraph>

                <Paragraph>
                  <Link to="/philosophy" style={{ color: 'inherit', textDecoration: 'underline' }}>
                    {language === 'en' ? 'See our philosophy' : 'Voir notre philosophie'}
                  </Link>
                </Paragraph>

                <SectionTitle>
                  {language === 'en' ? 'Headcount is not a success metric anymore' : 'Le nombre d\'employés n\'est plus un indicateur de succès'}
                </SectionTitle>

                <Paragraph>
                  Les entreprises les plus performantes d'aujourd'hui se concentrent sur la maximisation de l'impact par employé plutôt que sur l'expansion des équipes.
                  Cette évolution représente un changement fondamental dans le fonctionnement des entreprises de logiciels, privilégiant l'effet de levier technologique plutôt que les effectifs.
                </Paragraph>

                <Paragraph>
                  Ce n'est pas nouveau, comme Navil Ravikant, fondateur d'AngelList, <a href="https://www.youtube.com/watch?v=rIXx617xVMo" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'underline' }}>le dit depuis des années</a>. Mais l'IA accélère considérablement cette tendance.
                  D'un côté, nous avons des startups valorisées à plusieurs milliards de dollars gérées par des équipes de 10-20 personnes. De l'autre, nous avons des entreprises de plus de 1000 employés qui peinent à suivre le rythme du changement, enlisées dans la bureaucratie et donc extrêmement coûteuses. <a href="https://www.youtube.com/watch?v=z0wt2pe_LZM" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'underline' }}>Y Combinator</a>, qui a contribué à la création de plus de 5000 startups avec une valorisation combinée de 600 milliards de dollars, prédit que nous verrons davantage d'entreprises milliardaires gérées par des équipes de moins de 10 personnes.
                </Paragraph>

                <Paragraph>
                  Si vous souhaitez en savoir plus sur les aspects techniques de notre approche et aimeriez que nous construisions votre produit, contactez-nous: <strong>hello@information-company.com</strong>
                </Paragraph>
              </>
            )}
          </Content>
          <ImageCredit>Image: DeepMind</ImageCredit>
        </FirstSection>

        <SecondSection ref={secondSectionRef}>
          <Content>
            <SectionTitle>{language === 'en' ? 'About the Founder & Chief Technology Officer' : 'À propos du Fondateur & Directeur Technique'}</SectionTitle>
            <MobileFounderImage 
              src={alexNesovic} 
              alt="Alex Nesovic - Founder & Chief Technology Officer of Infocom"
              loading="lazy"
            />
            {language === 'en' ? (
              <>
                <Paragraph>
                  Alex Nesovic, <strong>Founder & Chief Technology Officer of Infocom</strong>, has extensive experience 
                  in software development, AI-driven orchestration, and European financial technology markets.
                </Paragraph>
                
                <Paragraph>
                  Having worked in corporate environments across Europe, Alex witnessed firsthand how excessive bureaucracy and corporate jargon can stifle innovation 
                  and cripple product development. This experience shaped Infocom's core philosophy: <strong>rapid prototyping paired with professional-grade 
                  development</strong>, eliminating unnecessary barriers while maintaining Luxembourg's high standards.
                </Paragraph>
                
                <Paragraph>
                  Before founding Infocom in Luxembourg, Alex worked as a Senior Developer across Fortune 500 companies, where he developed expertise in 
                  <strong> scalable architectures</strong> and European market-driven software solutions. This background drives Infocom's ability to move fast 
                  while delivering efficient and durable solutions.
                </Paragraph>
              </>
            ) : (
              <>
                <Paragraph>
                  Alex Nesovic, <strong>Fondateur & Directeur Technique d'Infocom</strong>, possède une vaste expérience 
                  dans le développement de logiciels, l'orchestration basée sur l'IA, et les marchés européens de la technologie financière.
                </Paragraph>
                
                <Paragraph>
                  Ayant travaillé dans des environnements corporatifs à travers l'Europe, Alex a été témoin de première main de la façon dont la bureaucratie excessive et le jargon 
                  corporatif peuvent étouffer l'innovation et paralyser le développement de produits. Cette expérience a façonné la philosophie centrale d'Infocom : 
                  <strong>prototypage rapide associé à un développement de qualité professionnelle</strong>, éliminant les barrières inutiles tout en maintenant les normes élevées du Luxembourg.
                </Paragraph>
                
                <Paragraph>
                  Avant de fonder Infocom au Luxembourg, Alex a travaillé comme Développeur Senior dans des entreprises du Fortune 500, où il a développé une expertise dans 
                  <strong>les architectures évolutives</strong> et les solutions logicielles axées sur le marché européen. Ce parcours alimente la capacité d'Infocom à avancer rapidement 
                  tout en livrant des solutions efficaces et durables.
                </Paragraph>
              </>
            )}
          </Content>
          <FounderImageContainer>
            <FounderImage 
              src={alexNesovic} 
              alt="Alex Nesovic - Founder & Chief Technology Officer of Infocom"
              loading="lazy"
            />
          </FounderImageContainer>
        </SecondSection>
        <ScrollIndicator 
          onClick={scrollToSection}
          className={isFirstSection ? 'down' : 'up'}
          aria-label={isFirstSection ? 'Scroll down' : 'Scroll up'}
        >
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"/>
          </svg>
        </ScrollIndicator>
      </Container>
    </>
  );
}

export default AboutInfocom; 